@use "globals" as *;

$thumb-size: 18px;
$rail-size: 1rem;
$marks-size: 2rem;

@mixin track-styles {
  appearance: none;
  background: transparent;
  border: transparent;
}

@mixin thumb-styles {
  appearance: none;
  pointer-events: all;
  width: $thumb-size;
  height: $thumb-size;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: transparent;

  &:active {
    cursor: grabbing;
  }
}

.range-slider {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(#{$rail-size} + var(--gutter) * 2);
  --input-slider-color: rgba(var(--primary-color-rgb), 0.95);

  &.range-slider-theme-primary {
    --input-slider-color: rgba(var(--theme-primary-color-rgb), 0.95);
  }

  &-green {
    --input-slider-color: rgba(var(--green-color-rgb), 0.95);
  }

  &-input {
    width: calc(100% + #{$thumb-size});
    margin: 0 calc(#{$thumb-size} / -2);
    position: absolute;
    height: $thumb-size;
  }

  &-control {
    width: 100%;
    position: absolute;
    top: 0;
    height: $thumb-size;
  }

  &-marks {
    position: absolute;
    display: flex;
    align-items: center;
    height: var(--gutter);
    width: 100%;
    height: 2rem;
    bottom: 0;

    .mark {
      position: absolute;
      color: var(--primary-text-color);
      font-weight: 300;
      opacity: 0.9;
      margin-left: -0.35rem;

      &.active {
        font-weight: 600;
      }

      &:last-child {
        left: unset !important;
        right: 0;
      }
    }
  }

  input {
    position: absolute;
    width: 100%;
    pointer-events: none;
    appearance: none;
    height: 100%;
    opacity: 0;
    z-index: 3;
    padding: 0;

    &::-ms-track {
      @include track-styles;
    }

    &::-moz-range-track {
      @include track-styles;
    }

    &:focus::-webkit-slider-runnable-track {
      @include track-styles;
    }

    &::-ms-thumb {
      @include thumb-styles;
    }

    &::-moz-range-thumb {
      @include thumb-styles;
    }

    &::-webkit-slider-thumb {
      @include thumb-styles;
    }
  }

  .rail {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 6px;
    border-radius: var(--radius-sm);
    background: rgba(var(--primary-text-color-rgb), 0.15);
  }

  .inner-rail {
    position: absolute;
    height: 100%;
    background: var(--input-slider-color);
    border-radius: var(--radius-sm);
    opacity: 0.5;
    overflow: hidden;
  }

  .control {
    width: $thumb-size;
    height: $thumb-size;
    border-radius: 50%;
    position: absolute;
    background: var(--input-slider-color);
    border: 1px solid var(--primary-color);
    top: 50%;
    margin-left: calc(#{$thumb-size} / -2);
    transform: translate3d(0, -50%, 0);
    z-index: 2;
  }
}
